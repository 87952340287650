/*
 * Main and demo navigation arrays
 *
 * 'to' attribute points to the route name, not the path url
 */

import type { RouteLocation } from 'vue-router';

export type BadgeVariant = 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark';
export type MenuItem = {
    badge?: string;
    'badge-variant'?: BadgeVariant;
    name: string;
    to?: string | RouteLocation;
    icon?: string;
    heading?: boolean;
    subActivePaths?: string;
    open?: boolean;
    sub?: MenuItem[];
    target?: string;
};

export type Menu = {
    [key: string]: MenuItem[];
};

export default {
    main: [
        {
            name: 'Dashboard',
            to: 'dashboard',
            icon: 'fa-solid fa-lg fa-gauge',
        },
        {
            name: 'Shipments',
            to: 'orders',
            icon: 'fa-solid fa-lg fa-cart-shopping',
        },
        {
            name: 'Products',
            to: 'products',
            icon: 'fa-solid fa-lg fa-gift',
        },
        {
            name: 'SKUs',
            to: 'skus',
            icon: 'fa-solid fa-lg fa-gift',
        },
        {
            name: 'Stock',
            to: 'stocks',
            icon: '',
        },
        {
            name: 'Inventory',
            icon: 'fa-solid fa-lg fa-box',
            sub: [
                {
                    name: 'By Date',
                    to: 'inventory-by-date',
                    icon: 'fa-solid fa-lg fa-sheet-plastic',
                },
            ],
        },
        {
            name: 'Forecasts',
            to: 'forecasts',
            icon: 'fa-solid fa-chart-simple',
        },
        {
            name: 'Sales Channels',
            to: 'sales-channels.list',
            icon: 'fa-solid fa-building',
        },
        {
            name: 'Inventory',
            heading: true,
        },
        {
            name: 'Analysis',
            to: 'stocks-analysis',
            icon: 'fa-solid fa-lg fa-sheet-plastic',
        },
        {
            name: 'Purchase Orders',
            to: 'purchase-orders',
            icon: 'fa-solid fa-lg fa-list',
        },
        {
            name: 'Warehouse Transfers',
            to: 'transfers',
            icon: 'fa-solid fa-lg fa-truck',
            subActivePaths: 'transfers',
            open: true,
            sub: [
                {
                    name: 'All Transfers',
                    to: 'transfers',
                    icon: 'fa-solid fa-lg fa-list',
                },
                {
                    name: 'Upcoming Transfers',
                    to: {
                        name: 'transfers',
                        query: {
                            draft: 'true',
                            live: 'false',
                            completed: 'false',
                            sort: 'shipping_date',
                            sortDesc: 'false',
                        },
                    },
                    icon: 'fa-solid fa-lg fa-list',
                },
                {
                    name: 'Problem Transfers',
                    to: {
                        name: 'transfers',
                        query: {
                            draft: 'true',
                            live: 'true',
                            completed: 'true',
                            problems: true,
                        },
                    },
                    icon: 'fa-solid fa-lg fa-list',
                },
            ],
        },
        {
            name: 'Suppliers',
            to: 'suppliers',
            icon: 'fa-solid fa-lg fa-industry',
        },
        {
            name: 'Warehouses',
            to: 'warehouses',
            icon: 'fa-solid fa-lg fa-warehouse',
        },
        {
            name: 'Fulfillment Centers',
            to: 'fulfillment-centers',
            icon: '',
        },
        {
            name: 'Customer Support',
            heading: true,
        },
        {
            name: 'Orders',
            to: 'store-orders',
            icon: 'fa-solid fa-lg fa-cart-shopping',
            subActivePaths: 'store-orders',
            sub: [
                {
                    name: 'All Orders',
                    to: {
                        name: 'store-orders',
                    },
                    icon: 'fa-solid fa-lg fa-list',
                },
                {
                    name: 'Problem Orders',
                    to: {
                        name: 'store-orders',
                        query: {
                            problems: true,
                        },
                    },
                    icon: 'fa-solid fa-lg fa-list',
                },
            ],
        },
        {
            name: 'Disputes',
            to: 'disputes',
            icon: 'fa-solid fa-lg fa-file-circle-exclamation',
        },

        {
            name: 'DEBUGGING',
            heading: true,
        },
        {
            name: 'Health',
            to: 'health',
            icon: 'fa-solid fa-lg fa-heart',
        },
        {
            name: 'Report Requests',
            to: 'report-requests',
            icon: 'fa-solid fa-lg fa-sheet-plastic',
        },
    ],
} as Menu;
