import type { BadgeVariant } from '@/data/menu';
import { useMenuStore } from '@/stores/menuStore';
import { useOrganisationStore } from '@/stores/organisationStore';
import { defineStore } from 'pinia';

interface CountState {
    disputes: number;
    transfers: {
        drafts: number;
        problems: number;
    };
    skus: number;
    orders: number;
}

export const useCountStore = defineStore('counts', {
    state: (): CountState => ({
        disputes: 0,
        transfers: {
            drafts: 0,
            problems: 0,
        },
        skus: 0,
        orders: 0,
    }),

    actions: {
        async fetchAllCounts() {
            const organisationStore = useOrganisationStore();
            try {
                const response = await fetch(
                    `/api/organisations/${organisationStore.current_organisation.ulid}/counts`,
                );
                const data = await response.json();

                this.disputes = data.disputes ?? 0;
                this.transfers.drafts = data.transfers.drafts ?? 0;
                this.transfers.problems = data.transfers.problems ?? 0;
                this.skus = data.skus ?? 0;
                this.orders = data.orders ?? 0;

                this.updateMenuBadges();
            } catch (error) {
                console.error('Failed to fetch counts:', error);
            }
        },

        updateMenuBadge(label: string, count: number, variant: BadgeVariant = 'danger') {
            const menuStore = useMenuStore();

            if (count === 0) {
                menuStore.removeBadge(label);
            } else {
                menuStore.addBadge(label, count, variant);
            }
        },
        updateMenuBadges() {
            // Update Disputes badge
            this.updateMenuBadge('Disputes', this.disputes);
            this.updateMenuBadge('Upcoming Transfers', this.transfers.drafts, 'warning');
            this.updateMenuBadge('Problem Transfers', this.transfers.problems);
            this.updateMenuBadge('SKUs', this.skus);
            this.updateMenuBadge('Problem Orders', this.orders);
        },
    },
});
