import LaravelError from '@/api/model/LaravelError';
import * as Sentry from '@sentry/vue';
import { useToastController } from 'bootstrap-vue-next';
import { ref } from 'vue';

export type Errors = Record<string, string>;

export function useErrors() {
    const { show } = useToastController();

    const validationErrors = ref<Errors>({});

    function getTitle(error: unknown): string {
        if (error instanceof LaravelError) {
            switch (error.localCause?.response?.status) {
                case 401:
                    return 'Unauthorized';
                case 403:
                    return 'You do not have permission';
                case 404:
                    return 'Not Found';
                case 422:
                    return 'Validation Error';
                case 429:
                    return 'Too Many Requests';
                case 500:
                    return 'Server Error';
                default:
                    return 'Error';
            }
        }

        return 'Error';
    }

    function showErrors(error: unknown, message: string, category: string = 'unknown'): Errors {
        Sentry.addBreadcrumb({
            category: category ?? 'unknown',
            message: message,
            level: 'error',
        });
        Sentry.captureException(error);

        if (error instanceof Error) {
            console.error(message, error.message, error);
        } else {
            console.error(message, error);
        }
        if (show) {
            show({
                props: {
                    title: getTitle(error),
                    body: message,
                    variant: 'danger',
                },
            });
        }

        if (error instanceof LaravelError) {
            validationErrors.value = Object.fromEntries(
                Object.entries(error.errors).map(([key, value]) => [
                    key,
                    Array.isArray(value) ? value.join(', ') : value,
                ]),
            );
        }

        return error instanceof LaravelError ? validationErrors.value : {};
    }

    function clearErrors() {
        validationErrors.value = {};
    }

    return { showErrors, validationErrors, clearErrors };
}
